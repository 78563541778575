@import "../../../index.scss";
@import "styles/variables.scss";

.premium-header {
  height: 400px;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  h1,
  h3 {
    position: relative;
    top: 50%;
  }
  h3 {
    font-size: 20px;
  }
}

.premium-search__box {
  display: flex;
  flex-direction: row;
  padding: 20px 5%;
  justify-content: space-around;
  align-items: center;
  .premium-search__text {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: 5%;
  }
  .premium-search__input {
    display: flex;
    width: 40%;
    height: 40px;
    input {
      width: 80%;
      height: 36px;
    }
    button {
      border-radius: 0px 6px 6px 0px;
      margin: 0px;
      padding: 12px;
      height: 40px;
    }
  }
}
