@import "styles/variables.scss";
@import "styles/mixins.scss";

.searcher-form {
  flex: 1;
  background-color: $primary-white;
  padding: 15px 15px;
  gap: 10px;
  display: grid;
  .reset {
    padding: 12px 5px;
    background-color: $blue;
    color: white;
    border: none;
    &:hover {
      background-color: $dark-blue;
      cursor: pointer;
    }
  }
  .submit-button {
    padding: 0;
    background-color: unset;
    margin: 0;
    width: 36px;
    height: 60px;
    background-color: $blue;
    border-radius: 0%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .NSI-select-drop-down-menu-wrapper {
    z-index: 10;
  }
  .searcher-form-row:first-child div:nth-child(4) {
    flex-grow: 1;
  }
  .searcher-form-row {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .main-glass-icon {
      margin-top: 0;
      height: 35px;
    }

    .myCustom_text {
      margin-top: 5px;
      padding: 0;
      max-height: 57px;
      .NSI-main-input-flex-box {
        margin-left: 7px;
      }
    }
    input:not(.MuiAutocomplete-input) {
      height: 57px !important;
      margin-top: 0 !important;
      font-size: 16px;
      padding: 0;
      border: 1px solid $primary-white;
    }
    .location-input,
    .location-input div {
      // Need to overwrite Mui compoment build in styles
      max-width: 250px !important;
      padding: 0;
      padding-right: 0 !important;
      flex-wrap: nowrap;
      input {
        padding: 0;
      }
    }

    .Mui-expanded {
      max-width: 500px !important;
      // position: absolute;
      right: 0;
      top: 0;
      .location-input,
      .location-input div {
        // Need to overwrite Mui compoment build in styles
        max-width: 500px !important;
      }
    }

    .text-input {
      flex: 1 1 40px;

      input {
        padding: 0 10px;
      }
    }
    .twin-input {
      align-items: center;
      display: flex;
      gap: 10px;
    }
  }
}

.small-searcher-form {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding-top: 8px !important;
    padding-bottom: 9px !important;
  }
}

@media (min-width: 1023px) {
  .searcher-form {
    .fade-on-big {
      display: none;
    }
  }
}

@media (max-width: 1023px) {
  .searcher-form {
    .searcher-form-row {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .select-input,
      .text-input {
        width: 100%;
      }

      .fade-on-mid {
        display: none;
      }
    }
    .submit-button {
      color: $primary-white;
      font-size: $font-default;
      padding: 11px;
      display: flex;
      align-items: center;
      gap: 5px;
      width: 100%;
      border-radius: 15px;
    }

    .reset {
      padding: 16px;
    }
  }
}

@media (max-width: 1023px) {
  .searcher-form {
    width: auto;
  }

  .searcher-form-row:first-child div:nth-child(1),
  .searcher-form-row:first-child div:nth-child(4) {
    grid-column: span 2;
  }
}
