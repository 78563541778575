@import "../../../../../index.scss";
@import "styles/variables.scss";

.commision-header {
  height: 400px;
  width: 100%;
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.5) 100%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../../../../../images/commisionbg.webp");
  background-position-y: 30%;
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 60px;
  align-items: center;
  text-align: center;
  h1 {
    color: white;
    font-size: 45px;
  }
}
.commmision-article {
  margin: 110px 25%;
  line-height: 1.8;
  text-align: center;
  margin-bottom: 200px;
  h1,
  h3 {
    margin-top: 70px;
  }
}

.commision-table {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 0.5fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  text-align: center;
}

.title1 {
  grid-area: 1 / 1 / 2 / 2;
}
.text1 {
  grid-area: 2 / 1 / 3 / 2;
}
.text2 {
  grid-area: 3 / 1 / 4 / 2;
}
.text3 {
  grid-area: 4 / 1 / 5 / 2;
}
.text4 {
  grid-area: 5 / 1 / 6 / 2;
}
.title2 {
  grid-area: 1 / 2 / 2 / 3;
}
.text5 {
  grid-area: 2 / 2 / 3 / 3;
}
.text6 {
  grid-area: 3 / 2 / 4 / 3;
}
.text7 {
  grid-area: 4 / 2 / 5 / 3;
}
.text8 {
  grid-area: 5 / 2 / 6 / 3;
}
.title3 {
  grid-area: 1 / 3 / 2 / 4;
}
.text9 {
  grid-area: 2 / 3 / 3 / 4;
}
.text10 {
  grid-area: 3 / 3 / 4 / 4;
}
.text11 {
  grid-area: 4 / 3 / 5 / 4;
}
.text12 {
  grid-area: 5 / 3 / 6 / 4;
}

@media (max-width: 900px) {
  .commision-header h1 {
    font-size: 25px;
  }
  .commision-table {
    h3 {
      font-size: 12px;
    }
    p {
      font-size: 10px;
    }
  }
  .commmision-article {
    width: 96%;
    margin: 2%;
    h1 {
      font-size: 18px;
    }
  }
}
