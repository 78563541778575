@import "../../../../index.scss";
@import "styles/variables.scss";

.prep-head {
  margin-top: 60px;
  width: 100%;
  height: 400px;
  background-image: url("../../../../images/prep.webp");
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  h1 {
    padding: 100px 20%;
    // background-color: rgba($color: #FFF, $alpha: 0.7);
    color: white;
    font-size: 38px;
  }
}
.prep-text {
  padding: 50px 15%;
  text-align: center;
  line-height: 1.6;
  h2 {
    font-weight: 100;
    font-size: 28px;
    color: $dark-gray;
  }
  h1 {
    color: $dark-gray;
    font-size: 24px;
  }
  h3 {
    color: $dark-gray;
    font-size: 20px;
  }
}
@media (max-width: 900px) {
  .prep-head {
    h1 {
      padding: 40px 5%;
      font-size: 26px;
    }
  }
  .prep-text {
    padding: 50px 5%;
    h1 {
      font-size: 18px;
    }
    h2 {
      font-size: 18px;
      line-height: normal;
    }
  }
}
