@import "../../../index.scss";
@import "styles/variables.scss";

.auth-poster {
  background-color: $gray;
  padding: 10px;
  border-radius: 4px;
  min-width: 150px;
  max-width: 160px;
  margin: 10px 0;
  .auth-poster-row {
    display: flex;
    align-items: center;
    font-size: $font-small;
    gap: 5px;
    margin-bottom: 10px;
    svg {
      font-size: 40px;
    }
  }
  .auth-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: $font-small;

    .auth-section-element {
      border-bottom: 1px solid $black;
      padding-bottom: 2px;
      cursor: pointer;
    }
  }
}
