@import "../../../index.scss";
@import "styles/variables.scss";
.small {
  display: none;
}
.desktop {
  display: block;
}
.mobile {
  display: none;
}

.login-box {
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 420px;
  height: auto;
  overflow-y: hidden;
  padding: 15px;
  h2 {
    padding: 10px;
    font-size: 30px;
    margin: 10px;
    text-align: center;
    color: $dark-blue;
  }
  .checkbox-box {
    display: flex;
    align-items: center;
    padding: 0px 5px;
    span {
      font-size: 12px;
      color: $dark-gray;
      cursor: pointer;
    }
  }
  .remember-password {
    display: flex;
    justify-content: flex-end;
    padding: 0px 20px;
  }
  img {
    height: 50px;
    width: 150px;
    align-self: center;
    margin-top: 20px;
  }
  h3 {
    margin: 10px 0px 0px 0px;
    text-align: center;
  }

  form {
    display: flex;
    padding: 15px 0px;
    flex-direction: column;
    justify-content: space-between;
    p {
      color: $dark-gray;
      cursor: pointer;
      transition: 0.2s ease-in-out;
    }
  }
}

.reset-box {
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 25px;
    text-align: center;
    padding: 15px 0px 0px 0px;
  }
  form {
    display: flex;
    flex-direction: column;
    padding: 20px 40px 40px 40px;

    button {
      padding: 10px;
      background-color: $blue;
      color: white;
      font-weight: 700;
      border: none;
      margin: 15px 0px;
      cursor: pointer;
      &:hover {
        background-color: $dark-blue;
      }
      &:disabled {
        cursor: auto;
        background-color: $dark-gray;
      }
    }
  }
}
.register-box {
  width: 420px;
  height: auto;
  overflow-y: auto;
}

.reset-password-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: $gray;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .reset-back-but {
    display: flex;
    flex-direction: row;
    align-items: center;
    transform: translateX(-50px);
    color: $dark-gray;
    height: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid transparent;
    padding-bottom: 1px;

    &:hover {
      color: $blue;
      border-bottom: 1px solid $blue;
      padding-bottom: 1px;
      cursor: pointer;
    }
  }
  .reset-password-box {
    display: flex;
    flex-direction: column;
    background-color: white;
    max-width: 300px;
    width: 100%;
    height: 500px;
    box-shadow: 2px -1px 14px 0px rgba(66, 68, 90, 0.62);
    border-radius: 24px;
    padding: 20px;
    form {
      display: flex;
      flex-direction: column;
      height: 100%;
      .error-info {
        font-size: 9px;
        color: $alert-red;
        margin: 1px 0px;
      }
    }
  }
}

@media (max-width: 900px) {
  .mobile {
    display: block;
    display: flex;
    place-content: center;
    align-items: center;

    svg {
      font-size: 25px;
    }
  }
  .login-box,
  .register-box {
    h2 {
      font-size: 24px;
      text-align: center;
      margin: 2px;
    }
    form {
      padding: 10px 0px;
    }
  }
}
@media (max-width: 600px) {
  .css-sw3rrj-MuiPaper-root-MuiDialog-paper {
    width: 100%;
  }
  .login-box,
  .register-box {
    width: auto;
  }
}
