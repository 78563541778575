@import "styles/variables.scss";

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip-text {
  visibility: hidden;

  background-color: $black;
  color: $primary-white;
  font-size: $font-extra-small;
  text-align: center;
  border-radius: 6px;
  padding: 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 4;
  bottom: 30px;
  left: -6px;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}
