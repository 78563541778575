@import "index.scss";
@import "styles/variables.scss";
.share-dialog-box {
  display: flex;
  flex-direction: column;
  .share-dialog-title {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 20px;
      $color: $dark-gray;
    }
    svg {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .share-dialog-content {
    display: flex;
    flex-direction: column;
    height: 150px;
    padding-top: 25px;
    justify-content: space-around;
    button {
      padding: 16px 0px;
      background-color: $blue;
      color: white;
      font-size: 14px;
      border-radius: 8px;

      &:hover {
        background-color: $dark-blue;
        cursor: pointer;
      }
    }
  }
}
