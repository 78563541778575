@import "../../../index.scss";
@import "styles/variables.scss";

nav {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .logo {
    height: 40px;
    margin: 5px 20px;
  }
  .beta {
    position: absolute;
    width: 70px;
    top: 22px;
    left: 104px;
    z-index: 3;
  }
  .profile-avatar {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.1 ease-in-out;
    margin: 0px 5px;
    &:hover {
      filter: brightness(1.3);
    }
  }
  .nav-bar {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding-right: 50px;
    align-items: center;
    font-size: 11px;
    z-index: 3;

    .desktop {
      li,
      .contact-link,
      .phone-box {
        height: 50px;
        display: grid;
        align-items: center;
      }
    }

    li,
    .contact-link {
      padding: 0px 15px;
      cursor: pointer;
      color: $primary-white;
      transition: 0.1s ease-in-out;
      text-decoration: none;
      text-align: center;
      &:hover {
        color: $mid-gray;
      }
    }

    .nav-login-bar {
      display: flex;
      align-items: center;
      flex-direction: row;
      list-style-type: none;

      .add-offer-button {
        display: flex;
        justify-content: center;
        width: 110px;
      }

      .outlined-link {
        background-color: transparent;
        padding: 8px;
        color: $primary-white;
        border: 1px solid $primary-white;
        margin: 0px 2px;
        font-weight: 600;
        &:hover {
          background-color: $primary-white;
          color: $dark-gray;
        }
      }
    }
  }
}

.buy-drop {
  z-index: 2;
  display: grid;
  height: 150px;
  width: 470px;
  position: absolute;
  top: 70px;
  right: 10px;
  background-color: $primary-white;
  color: $black;
  grid-template-columns: repeat(2, 0.8fr) 1.5fr;
  grid-template-rows: repeat(7, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  transform: translateX(-100px);

  padding: 15px;
  -webkit-box-shadow: -1px 11px 18px -7px rgba(49, 49, 49, 1);
  -moz-box-shadow: -1px 11px 18px -7px rgba(49, 49, 49, 1);
  box-shadow: -1px 11px 18px -7px rgba(49, 49, 49, 1);
  h3 {
    margin: 0px;
    padding-left: 5px;
    margin-bottom: 10px;
  }
  p,
  a {
    margin: 2px;
    font-size: 12px;
    text-decoration: none;
    color: black;
    padding: 9px 4px;
    align-self: center;
    padding-left: 5px;
    transition: background 0.1s ease-in-out;
  }
  a:hover {
    background-color: $gray;
  }
}

.header1 {
  grid-area: 1 / 1 / 2 / 2;
}
.buy-drop1 {
  grid-area: 2 / 1 / 3 / 2;
}
.buy-drop2 {
  grid-area: 3 / 1 / 4 / 2;
}
.buy-drop3 {
  grid-area: 4 / 1 / 5 / 2;
}
.header2 {
  grid-area: 1 / 2 / 2 / 3;
}
.buy-drop4 {
  grid-area: 2 / 2 / 3 / 3;
}
.header3 {
  grid-area: 1 / 3 / 2 / 4;
}
.buy-drop5 {
  grid-area: 2 / 3 / 3 / 4;
}
.buy-drop6 {
  grid-area: 3 / 3 / 4 / 4;
}
.buy-drop7 {
  grid-area: 4 / 3 / 5 / 4;
}
.buy-drop8 {
  grid-area: 5 / 3 / 6 / 4;
}
.buy-drop9 {
  grid-area: 6 / 3 / 7 / 4;
}
.buy-drop10 {
  grid-area: 7 / 3 / 8 / 4;
}

.sell-drop {
  z-index: 2;
  height: 160px;
  width: 470px;
  position: absolute;
  top: 70px;
  right: 10px;
  background-color: $primary-white;
  color: $black;
  padding: 15px;
  -webkit-box-shadow: -1px 11px 18px -7px rgba(49, 49, 49, 1);
  -moz-box-shadow: -1px 11px 18px -7px rgba(49, 49, 49, 1);
  box-shadow: -1px 11px 18px -7px rgba(49, 49, 49, 1);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  right: 20px;

  h3 {
    margin: 0px;
    padding-left: 5px;
    padding-bottom: 5px;
  }
  p,
  a {
    margin: 2px;
    font-size: 12px;
    text-decoration: none;
    color: black;
    padding: 0px 4px;
    height: 48px;
    align-self: center;
    padding-left: 5px;
    transition: background 0.1s ease-in-out;
    display: flex;
    align-items: center;
  }
  a:hover {
    background-color: $gray;
  }
}

.sell-drop1 {
  grid-area: 1 / 1 / 2 / 2;
}
.sell-drop2 {
  grid-area: 2 / 1 / 3 / 2;
}
.sell-drop3 {
  grid-area: 3 / 1 / 4 / 2;
}
.sell-drop4 {
  grid-area: 1 / 2 / 2 / 3;
}
.sell-drop5 {
  grid-area: 2 / 2 / 3 / 3;
}
.sell-drop6 {
  grid-area: 3 / 2 / 4 / 3;
}
.sell-drop7 {
  grid-area: 4 / 2 / 5 / 3;
}
.sell-drop8 {
  grid-area: 1 / 3 / 2 / 4;
}
.sell-drop9 {
  grid-area: 2 / 3 / 3 / 4;
}
.sell-drop10 {
  grid-area: 3 / 3 / 4 / 4;
}
.sell-drop11 {
  grid-area: 4 / 3 / 5 / 4;
}
.sell-drop12 {
  grid-area: 5 / 3 / 6 / 4;
}

.rent-drop {
  z-index: 2;
  height: 160px;
  width: 400px;
  transform: translateX(100px);
  position: absolute;
  top: 70px;
  right: 10px;
  background-color: $primary-white;
  color: $black;
  padding: 15px;
  -webkit-box-shadow: -1px 11px 18px -7px rgba(49, 49, 49, 1);
  -moz-box-shadow: -1px 11px 18px -7px rgba(49, 49, 49, 1);
  box-shadow: -1px 11px 18px -7px rgba(49, 49, 49, 1);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  h3 {
    margin: 0px;
    padding-left: 5px;
    padding-bottom: 9px;
  }
  p,
  a {
    margin: 2px;
    font-size: 12px;
    text-decoration: none;
    color: black;
    padding: 10px 4px;
    align-self: center;
    padding-left: 5px;
    transition: background 0.1s ease-in-out;
    display: flex;
    align-items: center;
  }
  a:hover {
    background-color: $gray;
  }
}

.rent-drop1 {
  grid-area: 1 / 1 / 2 / 2;
}
.rent-drop2 {
  grid-area: 2 / 1 / 3 / 2;
}
.rent-drop3 {
  grid-area: 3 / 1 / 4 / 2;
}
.rent-drop4 {
  grid-area: 1 / 2 / 2 / 3;
}
.rent-drop5 {
  grid-area: 2 / 2 / 3 / 3;
}
.rent-drop6 {
  grid-area: 3 / 2 / 4 / 3;
}
.rent-drop7 {
  grid-area: 4 / 2 / 5 / 3;
}
.rent-drop8 {
  grid-area: 5 / 2 / 6 / 3;
}
.add-offer-button {
  width: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.phone-box {
  width: 90px;
}
.nav-user__greeting {
  font-size: 12px;
  color: white;
}
.nav-user {
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.profile-drawer {
  width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  .profile-user {
    height: 180px;
    width: 100%;
    background: linear-gradient(
      156deg,
      rgba(11, 3, 56, 1) 0%,
      rgb(3, 20, 171) 100%
    );
    p {
      border: none;
      width: 230px;
      background: transparent;
      margin: 0px 0px 0px 20px;
      font-size: 14px;
      align-self: flex-start;
      color: white;
      font-weight: 600;
    }
    .email {
      margin: 0px 20px;
      color: white;
      font-family: Lato;
    }
  }
  .profile-but {
    width: 100%;
    height: 42px;
    border-bottom: 1px solid $gray;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $dark-gray;
    margin: 0px;
    svg {
      margin: 14px;
      font-size: 18px;
    }

    &:hover {
      background-color: $gray;
      cursor: pointer;
    }
  }
}
.nav2 {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  height: 76px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: $primary-white;
  border-bottom: 1px solid $gray;
  -webkit-box-shadow: 1px 12px 38px -9px rgba(66, 68, 90, 0.2);
  -moz-box-shadow: 1px 12px 38px -9px rgba(66, 68, 90, 0.2);
  box-shadow: 1px 12px 38px -9px rgba(66, 68, 90, 0.2);
  .logo {
    height: 40px;
    margin: 13px 20px 0px;
  }
  .beta {
    position: absolute;
    width: 70px;
    top: 30px;
    left: 104px;
  }
  .profile-avatar {
    object-fit: cover;
    cursor: pointer;
    &:hover {
      filter: brightness(0.9);
    }
  }
  .nav-bar2 {
    color: $dark-gray;
    li,
    .contact-link {
      color: black;
      cursor: pointer;
      &:hover {
        color: $mid-gray;
      }
    }
    .premium {
      color: #9d8420;
      font-weight: 600;
    }
    .nav-login-bar2 {
      a {
        border: solid 1px $dark-gray;
        display: flex;
        align-items: center;

        color: $dark-gray;

        &:hover {
          color: white;
          background-color: $dark-gray;
        }
      }
    }
  }
}

.nav-searcher-box {
  display: flex;
  align-items: center;
  .small-searcher-form {
    width: 350px;
  }
}
.phone-box {
  width: 90px;
}
.desktop {
  display: flex;
  margin-left: 18px;
  svg {
    margin-right: 5px;
  }
}
.mobile-menu {
  display: none;
}
.nav2 .nav-bar2 .nav-login-bar2 li.mobile,
nav .nav-bar .nav-login-bar li.mobile {
  border: 1px solid white;
  svg {
    width: 16px;
    height: 16px;
    padding: 2px 4px 3px;
  }
}
.nav2 .nav-bar2 .nav-login-bar2 li.small {
  display: none;
}

@media (max-width: 1200px) {
  nav,
  .nav2 {
    .nav-searcher-box {
      .desktop {
        display: none;
      }
      .logo {
        margin: 0px;
        height: 32px;
      }
      .beta {
        left: 68px;
        width: 50px;
        top: 22px;
      }
    }
  }
}
nav {
  .nav-bar {
    padding-right: 0px;
    .phone-box {
      padding: 0px 10px;
      text-decoration: none;
      color: white;
      &:hover {
        color: $mid-gray;
      }
    }
    .nav-login-bar {
      padding-left: 10px;
    }
  }
}
.nav2 .nav-bar2 {
  padding-left: 0px;
  .phone-box {
    color: $dark-gray;
    &:hover {
      color: $mid-gray;
    }
  }
}
@media (max-width: 900px) {
  .nav2 .nav-bar2 {
    .nav-user {
      a {
        background-color: white;
        border: 1px solid $dark-gray;
        color: $dark-gray;
        text-align: center;
      }
    }
    .nav-login-bar2 li.mobile,
    nav .nav-bar .nav-login-bar li.mobile {
      display: flex;
      align-items: center;
      svg {
        height: 12px;
        width: 16px;
        padding: 8px 8px 9px;
        border: 1px solid $dark-gray;
      }
    }
  }
  nav {
    .nav-user {
      a {
        background-color: transparent;
        padding: 8px;
        color: $primary-white;
        border: 1px solid $primary-white;
        margin: 0px 2px;
        font-weight: 600;
        &:hover {
          background-color: $primary-white;
          color: $dark-gray;
        }
      }
    }
    .nav-bar {
      .nav-login-bar {
        li {
          padding: 4px;
        }
      }
    }
    .desktop {
      display: none;
    }
    .small {
      display: block;
      place-content: center;
      align-items: center;

      svg {
        font-size: 25px;
      }
    }
  }
  .nav2 .nav-bar2 .nav-login-bar2 li.small {
    display: block;
    place-content: center;
    align-items: center;

    svg {
      font-size: 25px;
    }
  }
  li.mobile {
    z-index: 3;
  }
  .mobile-menu {
    position: absolute;
    top: 70px;
    background-color: white;
    z-index: 3;
    width: 90vw;
    left: 7%;
    border-radius: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    li,
    a {
      text-decoration: none;
      list-style-type: none;
      padding: 9px 0px;
      width: 100%;
      font-size: 14px;
      text-align: center;
      color: black;
      cursor: pointer;
      &:first-of-type {
        border-top: 1px solid $gray;
        border-radius: 20px 20px 0px 0px;
      }
      &:hover {
        background-color: $gray;
        border-radius: 20px;
      }
    }
    .submenu {
      display: flex;
      flex-direction: column;
      width: 100%;
      a {
        width: 100%;
        font-size: 12px;
      }
    }
  }

  .nav2 {
    .mobile-menu {
      -webkit-box-shadow: -1px 11px 18px -7px rgba(49, 49, 49, 0.5);
      -moz-box-shadow: -1px 11px 18px -7px rgba(49, 49, 49, 0.5);
      box-shadow: -1px 11px 18px -7px rgba(49, 49, 49, 0.5);
    }
    .small {
      display: block;
      place-content: center;
      align-items: center;

      svg {
        font-size: 25px;
      }
    }
  }
}
@media (max-width: 500px) {
  nav,
  .nav2 {
    img {
      height: 32px;
      margin: 5px;
    }
    .nav-bar,
    .nav-bar2 {
      padding: 0px;
      .nav-login-bar,
      .nav-login-bar2 {
        padding: 0px;
        margin-bottom: 10px;
        padding-right: 5px;
      }
    }
  }
}
