@import "../../../../../index.scss";
@import "styles/variables.scss";

.agent-header {
  height: 400px;
  width: 100%;
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.5) 100%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../../../../../images/chooseagentbg.webp");
  background-position-y: 95%;
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 60px;
  align-items: center;
  h1,
  p {
    margin: 5px;
    color: white;
  }
  h1 {
    font-size: 40px;
  }
}
.agent-article {
  margin: 50px 20%;
  line-height: 1.8;
  h3 {
    margin: 80px 50px;
  }
  h1 {
    margin-top: 70px;
  }
}

@media (max-width: 900px) {
  .agent-article {
    margin: 20px 4%;
  }
}

@media (max-width: 500px) {
  .agent-header {
    h1 {
      font-size: 25px;
    }
  }
  .agent-article {
    h1 {
      font-size: 20px;
      margin-top: 40px;
    }
    h3 {
      margin: 30px 10px;
    }
  }
}
