@import "../../../index.scss";
@import "styles/variables.scss";
@import "styles/mixins.scss";

.offer-box {
  position: relative;
  width: 90%;
  max-width: 450px;
  margin: 10px auto;
  min-height: 420px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  -webkit-box-shadow: 7px 7px 13px -9px rgba(66, 68, 90, 0.5);
  -moz-box-shadow: 7px 7px 13px -9px rgba(66, 68, 90, 0.5);
  box-shadow: 7px 7px 13px -9px rgba(66, 68, 90, 0.5);
  .image-wrapper {
    min-height: 280px;
  }
  img {
    width: 100%;
    object-fit: cover;
    height: 280px;
  }
  .offer-box-ribbon {
    position: absolute;
    top: 0;
    left: 0;
    width: 55%;
    min-width: 220px;
    aspect-ratio: 1/1;
    background-color: transparent;
    transform: translate(-50%, -50%) rotateZ(-45deg);
    font-size: 12px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    .offer-box-ribbon-text {
      position: absolute;
      box-sizing: border-box;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 24px;
      margin: 0;
      padding: 0 15%;
      background-color: $red;
      bottom: 0;
      left: 0;
      color: white;
      font-size: 12px;
      text-align: center;
      white-space: nowrap;
    }
  }
}

.main-slider-container {
  min-height: 500px;
  width: 70%;
  padding: 100px 15% 0px;
  .slick-slide {
    height: auto;
  }
}
.slider-placeholder {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .slider-placeholder-image {
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    img {
      max-width: 100%;
      max-height: 300px;
      object-fit: contain;
    }
  }
}
.info {
  display: flex;
  flex-direction: flex-end;
  justify-content: space-between;
  padding: 0 5px 5px 5px;
  .location {
    height: 24px;
  }
  .location,
  .features {
    font-size: 14px;

    i {
      padding-right: 10px;
    }
  }
  .details-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .btn {
      @include blue-button;
    }
    .price {
      font-weight: 600;
    }
  }
}
@media (max-width: 500px) {
  .main-slider-container {
    width: 96%;
    padding: 20px 2% 0px;
    .info {
      .location {
        height: 18px;
      }
      .details-box {
        .btn {
          padding: 5px 10px;
          font-size: 10px;
        }
      }
    }
  }
}
