@import "../../../../index.scss";
@import "styles/variables.scss";

.rentguide__header {
  display: flex;
  flex-direction: row;
  margin-top: 70px;
  padding: 0px 10%;
  div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  img {
    width: 50%;
    object-fit: cover;
  }
}

.rentguide-why__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 10%;
  background-color: $gray;

  h1 {
    text-align: center;
  }
  .rentguide-why__gridbox {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 0.7fr);
    .div1 {
      grid-area: 1 / 1 / 2 / 4;
    }
    .div2 {
      grid-area: 1 / 4 / 2 / 7;
    }
    .div3 {
      grid-area: 2 / 1 / 3 / 3;
    }
    .div4 {
      grid-area: 2 / 3 / 3 / 5;
    }
    .div5 {
      grid-area: 2 / 5 / 3 / 7;
    }
    .div1,
    .div2,
    .div3,
    .div4,
    .div5 {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      margin: 20px;
      padding: 20px;
      border: 1px solid $mid-gray;
      background-color: white;
      -webkit-box-shadow: -1px 11px 18px -7px rgba(49, 49, 49, 1);
      -moz-box-shadow: -1px 11px 18px -7px rgba(49, 49, 49, 1);
      box-shadow: -1px 11px 18px -7px rgba(49, 49, 49, 1);
      h1 {
        width: 80%;
      }
      img {
        height: 100px;
        width: 100px;
      }
    }
  }
}
.rentguide-art {
  display: flex;
  padding: 60px 10%;
  justify-content: center;

  .rentguide-art__textbox {
    display: flex;
    width: 50%;
    flex-direction: column;
    padding-right: 100px;
    aside {
      display: flex;
      input {
        padding: 10px;
        width: 70%;
      }
    }
  }
  img {
    width: 25%;
    height: 25%;
  }
}

.rentguide-art__more {
  display: flex;
  flex-direction: column;
  background-color: $gray;
  padding: 50px 15%;
  .rentguide-art__flexbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    a {
      width: 200px;
      height: 200px;
      border: 1px solid $gray;
      padding: 20px;
      -webkit-box-shadow: -1px 11px 18px -7px rgba(49, 49, 49, 1);
      -moz-box-shadow: -1px 11px 18px -7px rgba(49, 49, 49, 1);
      box-shadow: -1px 11px 18px -7px rgba(49, 49, 49, 1);
      background-color: white;
      div {
        display: flex;
        flex-direction: column;
        img {
          width: 100%;
        }
        h4 {
          font-size: 12px;
          margin-bottom: 0px;
        }
        p {
          font-size: 12px;
        }
      }
    }
    h3 {
      width: 60%;
      line-height: 1.8;
    }
  }
}
@media (max-width: 800px) {
  .rentguide__header {
    flex-direction: column-reverse;
    div {
      width: 100%;
    }
    img {
      width: 100%;
    }
  }

  .rentguide-why__container {
    .rentguide-why__gridbox {
      display: flex;
      flex-direction: column;
      .div1,
      .div2,
      .div3,
      .div4,
      .div5 {
        margin: 10px 0px;
      }
    }
  }
  .rentguide-art {
    flex-direction: column-reverse;
    img {
      width: 100%;
      height: auto;
    }
    .rentguide-art__textbox {
      width: 100%;
      padding-right: 0px;
    }
  }

  .rentguide-art__more {
    .rentguide-art__flexbox {
      flex-direction: column;
      h3 {
        width: 100%;
        line-height: 1.6;
      }
    }
  }
}
