body {
  margin: 0px;
  padding: 0px !important;
  overflow: visible !important;
}
* {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.text-bold {
  font-weight: 700;
}
p {
  line-height: 1.7;
}
a {
  text-decoration: none;
}
