.error-notifications {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;
  gap: 5px;
  display: grid;
}
.error-notifications > * {
  bottom: unset !important;
  left: unset !important;
  right: 0px !important;
  position: relative !important;
  display: inline !important;
}
