@import "../../../../../index.scss";
@import "styles/variables.scss";

.events-search-header {
  height: 400px;
  margin-top: 70px;
  width: 60%;
  padding: 0px 20%;
  display: flex;
  align-items: center;
  article {
    width: 50%;
    padding-right: 10%;
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 40px;
      margin: 10px 0px;
    }
  }
  img {
    width: 40%;
    height: fit-content;
  }
}

.events-container {
  padding: 50px 15%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  .event-box {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 390px;
    margin: 30px 5px;
    text-align: center;
    -webkit-box-shadow: 1px 12px 38px -9px rgba(66, 68, 90, 0.2);
    -moz-box-shadow: 1px 12px 38px -9px rgba(66, 68, 90, 0.2);
    box-shadow: 1px 12px 38px -9px rgba(66, 68, 90, 0.2);
    h3 {
      margin: 0px;
      padding-top: 15px;
    }
    button {
      border: none;
      background-color: $blue;
      color: white;
      width: 200px;
      height: 40px;
      align-self: center;
      font-weight: 600;
      margin-top: -5px;
    }
  }
}
