@import "../../../../index.scss";
@import "styles/variables.scss";
@import "styles/mixins.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

.agent-info-dialog {
  .agent-info-top {
    display: flex;
    justify-content: space-between;
    img {
      width: 150px;
      height: 150px;
      object-fit: cover;
      border-radius: 50%;
    }
    svg {
      &:hover {
        cursor: pointer;
      }
    }
  }
  h1 {
    margin: 15px 0px 4px;
  }
  h2,
  h3 {
    margin: 4px 0px;
  }

  a {
    font-size: 23px;
    text-decoration: none;
    color: $dark-gray;
    margin-top: 24px;
    font-weight: 600;
    &:hover {
      color: $mid-gray;
    }
  }
}
.property-details {
  .motion-button-row {
    button {
      @include motion-button;
    }
  }
  .property-widget {
    h3 {
      border-bottom: 2px solid rgb(242, 244, 245);
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
    .auth-poster {
      background-color: $blue;
      color: $primary-white;
      .auth-section-element {
        border-bottom: 1px solid $primary-white;
      }
    }
  }
  aside {
    h2 {
      margin: 0px;
    }
    h3 {
      margin: 4px 0px;
    }
    hr {
      width: 100%;
      height: 1px;
      background-color: $dark-gray;
    }
    width: 20%;
    position: sticky;
    top: 170px;
    height: 100px;
    display: flex;
    flex-direction: column;
    .prop-but-1,
    .prop-but-2 {
      @include blue-button;

      padding: 16px 10px;
      margin: 3px;
      font-size: $font-default;
    }
  }
  .img-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    width: 70%;
    padding: 70px 15% 0px;
  }

  .img1-box {
    grid-area: 1 / 1 / 3 / 3;
  }

  .img2-box {
    grid-area: 1 / 3 / 2 / 4;
  }

  .img3-box {
    grid-area: 2 / 3 / 3 / 4;
  }
  .img1,
  .img2,
  .img3 {
    width: 100%;
    height: fit-content;
    object-fit: cover;
  }

  .about-box {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 70%;
    margin: 147px 15% 50px 15%;
    .text-box {
      margin: 0 8px;
      width: 70%;

      .offer-description {
        font-size: $font-small;
        line-height: 25px;
      }

      h1 {
        font-size: 28px;
        color: $dark-gray;
      }
      h3 {
        color: $dark-gray;
        border-bottom: 2px solid $gray;
        padding-bottom: 30px;
        padding-top: 20px;
      }
      li {
        line-height: 2;
      }
    }
  }

  .property-slider-container {
    margin-top: 120px;
    width: 800px;
    .slick-prev,
    .slick-next {
      display: none !important;
    }
    .property-slider-img {
      img {
        width: 800px;
        height: 400px;
        object-fit: cover;
        position: center;
        margin: auto;
      }
    }
    .small-slider {
      .slick-slide {
        width: 97px !important;
        margin: 2px;
      }
    }
    .sub-slider-img {
      img {
        width: 112px;
        height: 80px;
        object-fit: cover;
      }
    }
  }

  .property-nav {
    height: 70px;
    width: calc(100% - 300px);
    position: absolute;
    top: 70px;
    display: flex;
    padding: 0px 150px;
    background-color: white;
    z-index: 2;
    box-shadow: -1px 11px 18px -7px rgba(49, 49, 49, 0.3);
    .auth-poster {
      display: none;
    }
    .property-nav-box {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      a {
        text-decoration: none;
        color: $dark-gray;
        align-items: center;
        padding: 20px 20px;
        font-size: 14px;

        &:hover {
          background-color: $gray;
          color: black;
          border-bottom: 1px solid black;
        }
      }
      svg {
        transform: translateY(1px);
        &:first-of-type {
          margin-right: 4px;
        }
      }
    }
    .prop-nav-buttons-box {
      display: none;
    }
  }

  .property-nav.sticky {
    position: fixed;
    top: 0;
  }
  .property-icons {
    display: flex;
    align-items: center;
    svg {
      font-size: 20px;
      margin: 0px 4px;
      color: $dark-gray;
      &:hover {
        color: $blue;
        cursor: pointer;
      }
    }
  }
}
.message-dialog__container {
  min-height: 500px;
  overflow: hidden;
  background-color: white;
  display: flex;
  flex-direction: column;
  .message-dialog__title {
    display: flex;
    justify-content: space-between;
    h3 {
      font-size: 18px;
      margin-top: 0px;
    }
    svg {
      cursor: pointer;
    }
  }

  .message-dialog-agent__box {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    column-gap: 25px;

    .message-dialog-agent__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      transform: translateY(-10px);
      h1 {
        font-size: 24px;
        margin-bottom: 4px;
        color: $light-blue;
      }
      p {
        margin: 0px;
      }
    }
  }
  textarea {
    width: calc(100% - 30px);
    border-radius: 10px;
    padding: 10px;
    height: 190px;
    resize: none;
    margin-bottom: 10px;
  }
  .server-error-message {
    align-items: center;
  }
  .next-button {
    height: 60px;
    padding: 0 30px;
    &:hover {
      cursor: pointer;
    }
  }

  .textarea-error {
    outline: none !important;
    border-color: $red;
    border-width: 1.5px;
  }
  .text-error {
    color: $red;
    margin-top: 0;
  }

  .message-dialog {
    display: flex;

    .nextbut {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      h3 {
        margin: 0px;
        margin-right: 10px;
        font-weight: 100;
      }
      .message-phone {
        color: $light-blue;
        font-weight: 600;
        &:hover {
          color: $blue;
          padding-bottom: 5px;
          border-bottom: 1px solid $blue;
        }
      }
      button {
        @include blue-button;
        padding: 10px 20px;
        font-weight: 700;
      }
    }
  }

  .message-dialog__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 0.6fr 1fr 0.6fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 0px;
    button {
      transform: translateY(40px);
      grid-area: 5 / 2 / 6 / 3;
      background-color: $blue;
      color: white;
      &:hover {
        background-color: $dark-blue;
      }
    }
  }
  .label-1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .input-1 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .label-2 {
    grid-area: 1 / 2 / 2 / 3;
  }
  .input-2 {
    grid-area: 2 / 2 / 3 / 3;
  }
  .label-3 {
    grid-area: 3 / 1 / 4 / 2;
  }
  .input-3 {
    grid-area: 4 / 1 / 5 / 2;
  }
  .label-4 {
    grid-area: 3 / 2 / 4 / 3;
  }
  .input-4 {
    grid-area: 4 / 2 / 5 / 3;
  }
  p {
    margin-left: 0px;
  }
  input {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid $mid-gray;
  }
}

.image-gallery {
  margin: 15px 0 60px 0;

  nav {
    justify-content: left;
  }
}
.image-gallery-icon {
  z-index: 1;
}
.image-gallery-thumbnails-container {
  button {
    overflow: hidden;
  }
  img {
    margin: 0;
  }
}
@media (max-width: 1024px) {
  .property-details {
    .property-nav {
      width: 95%;
      padding: 0px 2.5%;
      height: 52px;
      a {
        padding: 16px 10px;
      }
    }
    .about-box {
      width: 100%;
      margin: 147px 10px 50px;
      .property-widget {
        width: 25%;
        padding-right: 5%;
        h2 {
          font-size: 16px;
          margin-left: 4px;
        }
        h3 {
          font-size: 12px;
          margin-left: 4px;
        }
        .prop-but-1,
        .prop-but-2 {
          padding: 8px 4px;
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .dialog {
    .MuiDialog-container {
      align-items: self-start;
      justify-content: flex-start;
      .MuiPaper-root {
        margin: 32px 2px;
        .message-dialog {
          display: grid;
          .MuiPaper-root {
            margin: 0;
            overflow-wrap: break-word;
          }
        }
      }
    }
  }
  .property-details {
    .property-nav {
      height: 110px;
      flex-direction: column;
      .auth-poster {
        display: initial;
        background-color: $blue;
        color: $primary-white;
        .auth-section-element {
          border-bottom: 1px solid $primary-white;
        }
      }
      .prop-nav-buttons-box {
        display: flex;
        width: 100%;
        flex-direction: row;
        gap: 10px;
        margin-bottom: 10px;
      }
      .prop-det,
      .prop-info {
        display: none;
      }
    }
    aside {
      flex-direction: row;
      width: unset;
      padding: 16px 8px 0 8px;
    }
    .property-widget.sticky {
      position: fixed;
      top: 0;
    }
    .about-box {
      display: inline;
      width: 70%;
      padding: 167px 10px 50px 10px;
      .text-box {
        margin-top: 147px;
        margin: 147px 0px 0px 0px;
        width: 95%;
        padding: 0px 2.5%;
        h1 {
          font-size: 28px;
          color: $dark-gray;
        }
        h3 {
          color: $dark-gray;
          border-bottom: 2px solid $gray;
          padding-bottom: 30px;
          padding-top: 20px;
        }
        li {
          line-height: 2;
        }
      }
      .property-widget {
        display: none;
      }
    }
  }
}
