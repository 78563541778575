@import "../../../../index.scss";
@import "styles/variables.scss";

.sellwith__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-top: 70px;
  padding: 0px 10%;

  .sellwith-aside__text {
    padding-right: 10%;
    display: flex;
    flex-direction: column;
    padding-top: 5%;
    .sellwith-aside__head {
      h3 {
        font-size: 22px;
      }
      p,
      a {
        font-size: $font-default;
        display: inline;
      }
      a {
        color: $blue;
        font-weight: 600;
      }
    }
  }
  img {
    width: 100%;
  }
}

.sellwith-quest__container {
  display: flex;
  flex-direction: column;
  padding: 20px 10% 80px;
  margin-top: 60px;
  background-color: $gray;

  h1 {
    text-align: center;
    font-size: 50px;
    margin-bottom: 60px;
  }
  .sellwith-quest__gridbox {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    justify-items: center;
    div {
      width: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      svg {
        font-size: 45px;
      }
    }
  }
}
.sellwith-ipad__container {
  display: flex;
  flex-direction: column;
  padding: 60px 10%;
  h1 {
    text-align: center;
    margin-bottom: 60px;
  }
  .sellwith-ipad__gridbox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 60px;

    img {
      width: 100%;
      object-fit: cover;
      height: 70%;
      min-height: 400px;
      min-height: 500px;
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      h3 {
        font-size: 22px;
      }
      p {
        font-size: $font-default;
      }
    }
  }
}

.sellwith-more__gridbox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 60px;
  padding: 60px 10%;
  img {
    width: 100%;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3 {
      font-size: 22px;
    }
    p {
      font-size: $font-default;
      width: 80%;
    }
  }
}

.sellwith-ready__gridbox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 60px;
  padding: 60px 10%;
  .main-top-searcher-box {
    display: flex;
    align-items: center;

    button {
      width: 45px;
      height: 45px;
      border: none;
      background-color: transparent;
      transform: translateY(-3px);
    }
  }
  img {
    width: 100%;
  }
  .sellwith-ready__gridbox__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3 {
      font-size: 22px;
    }
    p {
      font-size: $font-default;
      width: 80%;
    }
    .desktop {
      display: flex;
      flex-direction: row;
      padding-top: 5%;
      justify-content: left;
      input {
        padding: 10px;
        width: 70%;
      }
      a {
        background-color: $blue;
        color: white;
        text-decoration: none;
        padding: 10px;
        font-weight: 600;
      }
      svg {
        transform: translateY(2px);
      }
    }
    img {
      width: 100%;
    }
  }
}

.sellwith-opinions {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  h3,
  p {
    width: 50%;
    max-width: 600px;
  }
  h3 {
    font-size: 20px;
  }
  p {
    font-size: $font-default;
  }
  p:first-of-type {
    font-style: italic;
  }
  svg {
    font-size: 50px;
    margin: 20px;
  }
}
@media (max-width: 1000px) {
  .sellwith__container {
    padding: 0px 2%;
    place-items: center;
    img {
      height: 400px;
      object-fit: cover;
    }
  }
  .sellwith-quest__container {
    padding: 10px 2%;
    .sellwith-quest__gridbox {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .sellwith-ipad__container {
    padding: 50px 2%;
  }
  .sellwith-more__gridbox,
  .sellwith-ready__gridbox {
    padding: 10px 2%;
    img {
      height: 400px;
      object-fit: cover;
    }
  }
}

@media (max-width: 600px) {
  .sellwith__container {
    display: flex;
    flex-direction: column;
    .sellwith-aside__text {
      padding: 2% 0px;
      text-align: center;
    }
  }
  .sellwith-quest__container {
    h1 {
      font-size: 24px;
    }
  }
  .sellwith-ipad__container {
    h1 {
      font-size: 24px;
    }
    .sellwith-ipad__gridbox {
      display: flex;
      flex-direction: column-reverse;
      text-align: center;
    }
  }
  .sellwith-more__gridbox {
    display: flex;
    flex-direction: column;
    text-align: center;

    div p {
      width: 100%;
    }
  }
  .sellwith-ready__gridbox {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    div p {
      width: 100%;
    }
    img {
      display: none;
    }
  }
}
