@import "../../../index.scss";
@import "styles/variables.scss";
@import "styles/mixins.scss";

.catalog-flexbox {
  margin-top: 100px;
  .catalog-pagination__box {
    display: flex;
    align-items: center;
    position: relative;
  }
  .catalog-page-count__box {
    position: absolute;
    display: flex;
    align-items: center;
    p {
      font-size: 14px;
    }
  }
  .searcher-form button {
    box-shadow: none;
    margin-right: 0;
  }
  .catalog-container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    .catalog-sort__box {
      display: flex;
      justify-content: space-between;
      padding: 0 15px 15px;
      div {
        min-width: 70px;
      }
    }
    .extra-filters {
      box-shadow: none;
      margin: 0;

      .summary {
        min-height: unset;
        margin: 10px 0;
        height: 48px;
        width: fit-content;
        text-align: left;
        padding: 0;
        .MuiAccordionSummary-content {
          justify-content: left;
          align-items: center;
        }
        p {
          margin: 0;
        }
      }
      .filters {
        padding: 20px 0;
        display: grid;
        grid-template-columns: 1fr 100px 1fr 100px 1fr;
        border-bottom: 1px $mid-gray groove;

        hr {
          background-color: $mid-gray;
          width: 1px;
          border: none;
        }
        .grid-column {
          gap: 10px;
          display: grid;
        }
      }
    }
    .filters-box {
      padding: 10px;
      height: min-content;

      background-color: $primary-white;
      -webkit-box-shadow: -1px 11px 18px -7px rgba(49, 49, 49, 1);
      -moz-box-shadow: -1px 11px 18px -7px rgba(49, 49, 49, 1);
      box-shadow: -1px 11px 18px -7px rgba(49, 49, 49, 1);
      h1 {
        font-size: 22px;
        padding: 10px;
        color: $dark-gray;
      }
    }

    .catalog-box {
      display: flex;
      flex-direction: column;
      padding: 15px;
      .save-results {
        font-size: 15px;
        cursor: pointer;
      }
      h1 {
        text-align: center;
        margin-top: 50px;
      }

      .offer-line {
        align-self: center;
        text-decoration: none;
        color: black;
        border-bottom: 1px solid $mid-gray;
        margin-bottom: 8px;
        width: 100%;
        height: 240px;
        background-color: white;
        display: grid;
        grid-template-columns: 1fr 4fr 1fr;
        grid-template-rows: 0.5fr;
        grid-column-gap: 8px;
        grid-row-gap: 0px;
        .offer-description h4 {
          .visible-data {
            padding-right: 20px;
            margin-left: 8px;
          }
          .blurred-data {
            filter: blur(4px);
            padding-right: 0;
            white-space: nowrap;
          }
        }

        h3 {
          grid-area: 1 / 1 / 2 / 2;
          font-size: $font-default;
          align-self: center;
        }
        p {
          grid-area: 1 / 2 / 2 / 3;
          font-size: $font-default;
          align-self: center;
        }
        h4 {
          grid-area: 1 / 3 / 2 / 4;
          font-size: $font-default;
          align-self: center;
        }
        img {
          grid-area: 1 / 1 / 2 / 2;
          object-fit: cover;
          height: 230px;
          width: 280px;
          border-radius: 4px;
        }
        &:hover {
          background-color: $gray;
          transition: all 0.3s;
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .catalog-flexbox .catalog-container .extra-filters .filters {
    grid-template-columns: 1fr 20px 1fr 20px 1fr;
  }
}

@media (max-width: 575px) {
  .catalog-flexbox .catalog-container .extra-filters .filters {
    display: inline;
  }
  .catalog-flexbox .catalog-container .catalog-box .offer-line {
    display: inline-table;
    padding: 0px;
    text-align: center;
    img {
      width: 98%;
    }
  }

  .catalog-flexbox {
    .catalog-pagination__box {
      padding-top: 10px;
      flex-direction: column;
      align-items: center;
      .catalog-page-count__box {
        position: relative;
      }
    }
    .catalog-container {
      width: auto;
      .catalog-sort__box {
        flex-direction: column-reverse;
        align-items: center;
      }
    }
  }
}
