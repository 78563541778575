@import "../../../../index.scss";
@import "styles/variables.scss";
@import "styles/mixins.scss";

.house-search-header {
  height: 400px;
  margin-top: 70px;
  width: 60%;
  padding: 0px 20%;
  display: flex;
  align-items: center;
  article {
    width: 50%;
    padding-right: 10%;
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 40px;
      margin: 10px 0px;
    }
  }
  img {
    width: 40%;
  }
}

.house-search-bar {
  height: 100px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: end;
  .search-location {
    width: 250px;
  }
  .search-location,
  .search-price {
    padding-right: 20px;
    p {
      font-size: 12px;
      color: $dark-gray;
      margin-bottom: 5px;
    }
    .price-box {
      input {
        width: 250px;
        height: 38px;
        border: solid 1px $gray;
        padding-left: 4px;
      }
    }
  }
  .search-btn {
    @include blue-button;
    width: 250px;
    height: 44px;
    margin-top: 31px;

    font-weight: 600;
  }
}

.houses-container {
  h1 {
    padding: 0px 15%;
    font-size: 32px;
    margin-bottom: -50px;
  }
  border-bottom: 1px solid $gray;
}

.search-bar {
  height: 100px;
  display: flex;
  width: 100%;
  justify-content: center;
  .search-location,
  .search-price {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    p {
      font-size: 10px;
      color: $dark-gray;
      margin-bottom: 5px;
    }
    input {
      width: 300px;
      height: 40px;
      border: solid 1px $gray;
    }
  }
}
@media (max-width: 1130px) {
  .house-search-header {
    width: 90%;
    padding: 0px 5%;
  }
  .house-search-bar {
    flex-direction: column;
    height: auto;
    align-items: center;
    .search-price,
    .search-location {
      padding: 0px;
      width: inherit;
      max-width: 350px;

      .price-box {
        display: flex;
        flex-direction: column;
        input {
          width: auto;
          margin-bottom: 4px;
        }
      }
    }

    .search-btn {
      width: inherit;
      max-width: 350px;
    }
  }
}

@media (max-width: 900px) {
  .search-bar {
    flex-direction: column;
    height: auto;
    align-items: center;
    padding-bottom: 30px;
  }
}
@media (max-width: 500px) {
  .house-search-header {
    height: 280px;
    .house-search-header-box {
      h1 {
        font-size: 25px;
      }
    }
  }
  .houses-container h1 {
    margin-bottom: 0px;
  }
}
@media (max-width: 400px) {
  .house-search-bar {
    .search-price,
    .search-location {
      width: 90%;
    }
    .search-btn {
      width: 90%;
    }
  }
}
