@import url("../../../index.scss");
@import "styles/variables.scss";

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid transparent;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: auto;
  animation: spin 1s linear infinite;
}

button > .loader {
  width: 17px;
  height: 17px;
  margin: -5px auto;
}
