@import "../../../../index.scss";
@import "styles/variables.scss";
@import "styles/mixins.scss";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid $light-blue;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.add-offer_box {
  background-color: $gray;
  display: flex;
  flex-direction: column;
  padding: 60px 20%;

  button:not(.location-select button, .add-img-box button) {
    @include motion-button;
    &:hover {
      background-color: $dark-gray;
      color: white;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      -webkit-box-shadow: 4px 7px 31px 0px rgba(147, 147, 147, 0.5);
      -moz-box-shadow: 4px 7px 31px 0px rgba(147, 147, 147, 0.5);
      box-shadow: 4px 7px 31px 0px rgba(147, 147, 147, 0.5);
    }
  }
  h3,
  p {
    margin-left: 8px;
  }
  .NSI-main-wrapper {
    margin: 0 1%;
    .myCustom_text {
      margin-top: 5px;
      padding: 0;
      max-height: 57px;
      .NSI-main-input-flex-box {
        margin-left: 7px;
        height: 57px;
      }
    }
    .NSI-select-drop-down-menu-wrapper {
      z-index: 10;
    }
    input {
      font-size: 1rem;
    }
  }

  header {
    h1 {
      padding-top: 20px;
      padding-left: 30px;
      font-size: 36px;
    }
  }

  article {
    background-color: white;
    padding: 30px;
    margin-bottom: 15px;
    border-radius: 10px;
  }
}
h3 {
  font-size: 16px;
  color: $dark-gray;
}
p {
  font-size: 12px;
  color: gray;
}
.add-title {
  display: flex;
  flex-direction: column;
  h3 {
    margin-bottom: 6px;
  }
  .add-offer-max-char-info {
    margin-bottom: 15px;
  }
  .add-price {
    margin: 4px 0px;
  }
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  p {
    margin: 0px 0px 2px;
  }
}

.subtitle {
  color: $mid-gray;
  font-size: 15px;
  padding-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid $mid-gray;
}
.add-offer_description {
  textarea {
    width: calc(100% - 20px);
    height: 300px;
    resize: none;
    padding: 10px;
  }
}
.add-img-box {
  width: auto;
  min-height: 300px;
  display: flex;
  flex-wrap: wrap;
  input {
    text-align-last: center;
  }
}
.add-img-but {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $gray;
  width: 184px;
  height: 137px;
  margin: 8px;

  justify-content: center;
  border-radius: 4px;
  &:hover {
    border: 1px solid $dark-gray;
    background-color: $gray;
  }
}

.button-selected {
  background-color: $light-blue !important;
  color: white;
  &:hover {
    background-color: $blue;
  }
}
.button-showmore {
  width: 100%;
  justify-content: center;
  display: flex;
  margin-top: 10px;
  border-bottom: 1px solid $gray;
  button {
    background: none;
    box-shadow: none;
    color: $dark-gray;
    font-weight: 600;
    align-items: center;
    display: flex;
    &:hover {
      color: $light-blue;
      transition: all 0.2s ease-in-out;
    }
    * {
      font-size: 25px;
    }
  }
}
.add-offer-button {
  width: 50%;
  align-self: center;
  left: 25%;
  right: 25%;
  position: relative;
  padding: 10px;
  background-color: $blue;
  color: white;
  font-weight: 700;
  border: none;
  border-radius: 10px;
  margin: 15px 0px;
  cursor: pointer;
  &:hover {
    background-color: $dark-blue;
  }
}

.add-buttons-holder {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  button {
    padding: 12px 24px;
    background-color: $blue;
    color: white;
    &:hover,
    &:focus {
      background-color: $light-blue;
    }
  }
  .add-offer_box__delete {
    background-color: $gray;
    border: 1px solid $alert-red;
    color: $alert-red;
    &:hover,
    &:focus {
      background-color: $alert-red;
      color: white;
    }
  }
}
@media (max-width: 1200px) {
  .add-offer_box {
    padding: 60px 4%;
  }
}
