@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600&family=Roboto:wght@400&display=swap");

// COLORS

$primary-white: #ffffff;
$secondary-white: #dcdcdc;
$primary-grey: #595959;

$black: black;
$blue: #070751;
$light-blue: #4396ca;
$dark-blue: #06063a;
$light-grey: #eaeaea7b;
$gray: #f4f6f7;
$mid-gray: #c5c5c5cc;
$dark-gray: #4f4f4f;
$red: #ef233c;
$alert-red: #d90429;

//FONT SIZES

$font-extra-small: 10px;
$font-small: 12px;
$font-small-medium: 14px;
$font-default: 16px;

$roboto: "Roboto", sans-serif;
