@import "../../../index.scss";
@import "styles/variables.scss";
@import "styles/mixins.scss";

.reset-password-dialog,
.contact-request-dialog,
.search-criteria-delete-dialog,
.favorite-offer-delete-dialog,
.user-offer-delete-dialog,
.user-offer-duplicate-dialog,
.offer-image-delete-dialog {
  .dialog-wrapper {
    position: relative;
    flex-direction: column;
    overflow: none;
    max-width: 420px;
    height: auto;
    overflow: none;
    padding: 15px;

    .close-icon {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    .row {
      padding: 5px 0;
    }
    .button-section {
      margin-top: 15px;
      display: flex;
      gap: 30px;
      justify-content: center;
    }
    .message-wrapper {
      border: 1px solid $mid-gray;
      border-radius: 5px;
      min-height: 100px;
      .dialog-text {
        padding: 10px;
      }
    }
    hr {
      margin: 20px 0 10px 0;
    }
    .dialog-text {
      font-size: $font-small-medium;
    }
    .bold {
      font-weight: 600;
    }
    h4 {
      margin: 0;
    }
    .delete-question,
    .confirmation-question {
      text-align: center;
      margin-bottom: 10px;
    }
    .delete-question:nth-child(3),
    .confirmation-question:nth-child(3) {
      margin-bottom: 40px;
    }

    .delete-button {
      @include blue-button;
      transition: all 0.2s ease-in-out;

      &:disabled {
        opacity: 0.5;
      }
    }
    .cancel-button {
      @include blue-button;
      background-color: $light-blue;
    }
  }
  .delete-dialog,
  .confirmation-dialog {
    padding: 40px;
  }
}
.MuiTableCell-head {
  background-color: $gray;
}

.MuiTableRow-root td,
.MuiTableRow-root th:not(:first-child) {
  text-align: center;
}

.MuiTableRow-root td:last-child svg {
  margin: 0 2px;
  cursor: pointer;
}
.profile-nav {
  display: flex;
  position: absolute;
  top: 65px;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  background-color: $primary-white;
  overflow-x: scroll;
  min-width: 340px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  a {
    list-style-type: none;
    padding: 20px 0px;
    background-color: $primary-white;
    border-bottom: 1px solid $gray;
    color: $dark-gray;
    text-align: center;
    width: 112px;

    &:hover {
      background-color: $gray;
      border-bottom: 2px solid $dark-gray;
    }
  }
  .active {
    background-color: $gray;
  }
}
.profile-container {
  background-color: $gray;
  padding-bottom: 40px;
  padding-top: 20px;
  margin-top: 120px;
  display: flex;
  justify-content: center;
  .tooltip {
    button {
      border-radius: 50%;
      svg {
        border-radius: inherit;
      }
    }
  }

  .profile-page {
    margin: 20px 10%;
    width: 880px;
    background-color: $primary-white;
    border-radius: 30px;
    min-height: 400px;
    padding: 30px;

    h1 {
      font-size: 20px;
      margin-top: 0px;
      margin-bottom: 30px;
    }
    .profile-box {
      position: relative;
      display: flex;
      flex-direction: row;
      .profile-info {
        flex-basis: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .profile-pic {
          position: relative;
          flex-basis: 200px;
          width: 100%;
          img,
          .profile-edit {
            width: 180px;
            height: 180px;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 50%;
          }
          img {
            object-fit: cover;
            margin-bottom: 10px;
            &:hover ~ {
              .profile-edit {
                display: flex;
              }
            }
          }
          .profile-edit {
            display: none;
            flex-direction: column;
            text-align: center;
            place-content: center;
            background-color: rgba($color: #000000, $alpha: 0.4);
            &:hover {
              display: flex;
            }
            label {
              width: 180px;
              height: 180px;
              border-radius: 50%;
              svg {
                font-size: 35px;
                color: $gray;
                padding: 70px;
                align-self: center;
                cursor: pointer;
              }
            }
          }
        }

        .profile-data {
          width: 80%;
          .reset-password-info {
            padding: 10px 0;
            font-size: 12px;
            &.success {
              color: $light-blue;
            }
            &.error {
              color: $alert-red;
            }
          }
        }
        p {
          margin: 0px;
          font-size: 14px;
        }
        button {
          .loader {
            width: 15px;
            height: 15px;
          }
        }
      }
      .profile-details {
        flex-basis: 75%;
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        justify-content: space-around;
      }
    }
    .user-offer-img {
      width: 80px;
      height: 50px;
      object-fit: cover;
      margin: 7px 0px 0px 2px;
      border-radius: 4px;
    }

    .search-criterias-table {
      td.sort-order svg {
        width: 24px;
        height: 24px;
        color: $light-blue;
      }

      .more-locations {
        font-size: 12px;
        color: $dark-gray;
      }
    }

    button.profile-save {
      @include blue-button;
      padding: 15px;

      svg {
        padding: 10px;
      }
    }
    .profile-loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba($color: $primary-white, $alpha: 0.5);
    }
  }
  .preferences-page {
    display: flex;
    flex-direction: column;
    height: auto;
    .input-controller {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      input {
        font-size: 14px;
        border: none;
        color: $dark-gray;
        border-bottom: 1px solid $mid-gray;
        width: 100px;
        text-align: center;
        margin: 0px 0px 10px;
      }
    }
  }
  .tokens-page {
    ul {
      height: 200px;
      border: 1px solid black;
      overflow-y: auto;
      padding: 0px;
      li {
        padding: 10px;
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        button {
          padding: 6px 12px;
          border-radius: 6px;
          margin: 0px;
        }
        &:nth-child(2n) {
          background-color: $gray;
        }
      }
    }
    .new-token-box {
      display: flex;
      width: 100%;
      justify-content: space-between;
      div {
        display: flex;
        align-items: center;
        p {
          font-size: 14px;
          padding-right: 6px;
        }
        button {
          padding: 6px 12px;
          border-radius: 6px;
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .profile-nav {
    a {
      font-size: 11px;
    }
  }
  .profile-container {
    .profile-page {
      height: auto;
      .profile-box {
        flex-direction: column;
        .profile-info {
          width: 100%;
          justify-content: space-around;
          padding-bottom: 20px;
          .profile-pic {
            width: 140px;
            flex-basis: 140px;
            margin: 0px 20px 20px 0px;
            img {
              width: 140px;
              height: 140px;
            }
            .profile-edit {
              width: 140px;
              height: 140px;
              label {
                width: 140px;
                height: 140px;
                svg {
                  padding: 50px;
                }
              }
            }
          }
          .profile-data {
            position: initial;
          }
        }
        .profile-details {
          padding-left: 0px;
        }
      }
      .user-offer__box {
        .user-offer {
          grid-template-columns: 0.6fr 1fr 0.6fr;
          img {
            grid-area: 1 / 1 / 2 / 2;
          }
          .offer-title {
            grid-area: 1 / 2 / 2 / 3;
          }
          button {
            grid-area: 1 / 3 / 2 / 4;
          }
          .offer-type {
            display: none;
          }
          .offer-price {
            display: none;
          }
        }
      }
    }
  }
}
@media (max-width: 900px) {
  .contact-request-dialog {
    .dialog-wrapper {
      width: unset;

      .cut-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .muiDialog-paper {
      margin: 0 2%;
    }
  }
  .profile-container {
    padding-bottom: 0;
    padding-top: 0;

    table {
      min-width: unset;
    }
    .profile-page {
      padding: 20px 2%;
      margin: 0;
      border-radius: 0;

      .md-cell {
        display: none;
      }
    }
  }
}
@media (max-width: 600px) {
  .profile-container {
    .profile-page {
      .profile-offer-mobile {
        .user-offer {
          display: flex;
          flex-direction: row;
          .css-o4b71y-MuiAccordionSummary-content {
            margin: 0px 10px;
            text-align: center;
          }
          img {
            padding: 1px;
          }
          .offer-title {
            width: max-content;
            margin-left: 8px;
          }
        }
        .offer-details-mobile {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: center;

          a {
            width: 30%;
            margin: 10px 10px 0px;
          }
        }
      }
    }
  }
}
