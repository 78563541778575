@import "../../../index.scss";
@import "styles/variables.scss";
@import "styles/mixins.scss";

.main-glass-icon {
  font-size: 15px;
  height: 22px;
  width: 22px;
  padding: 10px;
  color: white;
  background-color: $blue;
  border-radius: 4px;
  &:hover {
    background-color: $dark-blue;
    cursor: pointer;
  }
}
.main-header {
  background-image: linear-gradient(
      176deg,
      rgba(52, 52, 52, 1) 0%,
      rgba(0, 0, 0, 0) 67%
    ),
    url("../../../images/back1.webp");
  height: 450px;
  width: 100%;
  background-size: cover;
  background-position: center;

  display: flex;
  align-items: center;

  .main-head-box {
    h1 {
      color: white;
      font-weight: 600;
      letter-spacing: 0.8px;
      margin: 0 0 30px;
    }
    input {
      width: 80%;
    }
    .input-holder {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .main-glass-icon {
      font-size: 15px;
      height: 14px;
      width: 14px;
      padding: 11px;
      color: white;
      background-color: $blue;
      &:hover {
        background-color: $dark-blue;
        cursor: pointer;
      }
    }
  }
}

.main-page {
  width: 70%;
  display: flex;
  margin: auto;
  flex-direction: column;
  .main-first {
    margin-top: 80px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    .main-img {
      height: 300px;
      width: 40%;
      -webkit-box-shadow: -1px 3px 18px -7px rgba(49, 49, 49, 1);
      -moz-box-shadow: -1px 3px 18px -7px rgba(49, 49, 49, 1);
      box-shadow: -1px 3px 18px -7px rgba(49, 49, 49, 1);
      object-fit: cover;
    }
    aside {
      width: 55%;
      h1 {
        font-size: 40px;
        margin: 15px 0px;
      }
      p {
        line-height: 1.4;
      }
      .main-top-searcher-box {
        display: flex;
        align-items: center;
        .main-top-searcher-content {
          @include blue-button;
        }
        input {
          height: 40px;
          width: 70%;
          font-size: 16px;
          border: 1px solid $gray;
        }
        p {
          padding: 12px 20px;
          color: white;
          font-weight: 700;
          background-color: $blue;
          &:hover {
            background-color: $dark-blue;
            cursor: pointer;
          }
        }
        button {
          width: 45px;
          height: 45px;
          border: none;
          background-color: transparent;
          transform: translateY(-3px);
        }
      }
    }
  }
}
.main-contact {
  margin-top: 100px;
  width: auto;
  height: 200px;

  background-color: $gray;
  padding: 0px 15%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .main-contact-text {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-self: center;

    h1 {
      font-size: 30px;
      margin-bottom: 10px;
      margin-top: 15px;
    }
    p {
      font-size: 15px;
    }
  }
  .main-contact-input {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-self: center;
    p {
      font-size: 16px;
      margin-bottom: 5px;
    }
    .input-holder {
      display: flex;
      flex-direction: row;
      align-items: center;
      .main-top-searcher-box {
        display: flex;
        align-items: center;

        button {
          width: 45px;
          height: 45px;
          border: none;
          background-color: transparent;
          transform: translateY(-3px);
        }
      }
    }
  }
}
.slider-title {
  width: 70%;
  padding: 0px 15%;
  h1 {
    font-size: 32px;
    padding-bottom: 30px;
    border-bottom: 2px solid $dark-gray;
  }
}

@media (max-width: 1023px) {
  .main-page {
    width: 96%;
    margin: 0px 2%;
    .main-first {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 140px;
      .main-img {
        width: 70%;
      }

      aside {
        width: 70%;
        .main-top-searcher-box {
          width: 100%;
        }
      }
    }
    .main-first:nth-of-type(2) {
      flex-direction: column-reverse;
      margin-bottom: 50px;
    }
  }
  .main-header {
    height: 500px;
    .main-head-box {
      h1 {
        font-size: 24px;
        margin: 0 0 20px;
      }
    }
  }
  .main-contact {
    width: 96%;
    padding: 2%;
    .main-contact-text {
      h1 {
        font-size: 20px;
      }
    }
    .main-contact-input {
      width: 50%;
    }
  }
}
@media (max-width: 500px) {
  .main-header {
    .main-head-box {
      h1 {
        font-size: 20px;
      }
      .main-head-choice {
        li {
          margin: 0px 10px 0px 0px;
        }
      }
    }
  }
  .main-page {
    min-width: 200px;
    .main-first {
      margin-top: 140px;

      aside {
        width: 90%;
        .main-top-searcher-box {
          input {
            font-size: 12px;
            width: 80%;
          }
          p {
            padding: 12px;
          }
        }
        h1 {
          font-size: 20px;
        }
      }
      .main-img {
        width: 90%;
      }
    }
  }
  .slider-title {
    width: 90%;
    padding: 0px 5%;
    h1 {
      font-size: 22px;
      padding-bottom: 10px;
    }
  }
  .main-contact {
    flex-direction: column;
    padding: 10px 2%;
    margin-top: 30px;
    height: auto;
    .main-contact-text {
      width: 100%;
      h1 {
        font-size: 16px;
      }
    }
    .main-contact-input {
      width: 100%;
      .input-holder input {
        font-size: 12px;
      }
    }
  }
}
